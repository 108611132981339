import smallPopIcon from "../assets/common/smallPopIcon.svg";
import smallRockIcon from "../assets/common/smallRockIcon.svg";
import smallBalladeIcon from "../assets/common/smallBalladeIcon.svg";
import smallJazzIcon from "../assets/common/smallJazzIcon.svg";
import smallHiphopIcon from "../assets/common/smallHiphopIcon.svg";
import smallLofiIcon from "../assets/common/smallLoFiIcon.svg";
import smallDanceIcon from "../assets/common/smallDanceIcon.svg";
import smallEtcIcon from "../assets/common/smallEtcIcon.svg";
import whitePopIcon from "../assets/common/whitePopIcon.svg";
import whiteRockIcon from "../assets/common/whiteRockIcon.svg";
import whiteBalladeIcon from "../assets/common/whiteBalladeIcon.svg";
import whiteJazzIcon from "../assets/common/whiteJazzIcon.svg";
import whiteHiphopIcon from "../assets/common/whiteHiphopIcon.svg";
import whiteLoFiIcon from "../assets/common/whiteLoFiIcon.svg";
import whiteDanceIcon from "../assets/common/whiteDanceIcon.svg";
import whiteEtcIcon from "../assets/common/whiteEtcIcon.svg";
import popIcon from "../assets/common/pop_Icon.svg";
import rockIcon from "../assets/common/rock_Icon.svg";
import balladeIcon from "../assets/common/ballade_Icon.svg";
import jazzIcon from "../assets/common/jazz_Icon.svg";
import hiphopIcon from "../assets/common/hiphop_Icon.svg";
import lofiIcon from "../assets/common/lo_fi_Icon.svg";
import danceIcon from "../assets/common/dance_Icon.svg";
import etcIcon from "../assets/common/etc_Icon.svg";
import popStroke from "../assets/images/UsersPage/stroke-icon-pop.svg";
import rockStroke from "../assets/images/UsersPage/stroke-icon-rock.svg";
import balladeStroke from "../assets/images/UsersPage/stroke-icon-ballade.svg";
import hiphopStroke from "../assets/images/UsersPage/stroke-icon-hiphop.svg";
import jazzStroke from "../assets/images/UsersPage/stroke-icon-jazz.svg";
import lofiStroke from "../assets/images/UsersPage/stroke-icon-lofi.svg";
import danceStroke from "../assets/images/UsersPage/stroke-icon-dance.svg";
import etcStroke from "../assets/images/UsersPage/stroke-icon-etc.svg";
import popStats from "../assets/introduce/popStats.svg";
import balladStats from "../assets/introduce/balladStats.svg";
import hiphopStats from "../assets/introduce/hiphopStats.svg";
import rockStats from "../assets/introduce/rockStats.svg";
import lofiStats from "../assets/introduce/lofiStats.svg";
import jazzStats from "../assets/introduce/jazzStats.svg";
import danceStats from "../assets/introduce/danceStats.svg";
import etcStats from "../assets/introduce/etcStats.svg";

export const GenreList = [
  {
    id: 0,
    name: "팝",
    EngName: "POP",
    imgSrc: smallPopIcon,
    whiteImgSrc: whitePopIcon,
    iconSrc: popIcon,
    strokeIconSrc: popStroke,
    width: 78,
    bgColor: "#4EDE76",
    statsIcon: popStats,
  },
  {
    id: 1,
    name: "록/메탈",
    EngName: "ROCK",
    imgSrc: smallRockIcon,
    whiteImgSrc: whiteRockIcon,
    iconSrc: rockIcon,
    strokeIconSrc: rockStroke,
    width: 119,
    bgColor: "#FF5862",
    statsIcon: rockStats,
  },
  {
    id: 2,
    name: "발라드",
    EngName: "BALLAD",
    imgSrc: smallBalladeIcon,
    whiteImgSrc: whiteBalladeIcon,
    iconSrc: balladeIcon,
    strokeIconSrc: balladeStroke,
    width: 113,
    bgColor: "#17C1D8",
    statsIcon: balladStats,
  },
  {
    id: 3,
    name: "재즈",
    EngName: "JAZZ",
    imgSrc: smallJazzIcon,
    whiteImgSrc: whiteJazzIcon,
    iconSrc: jazzIcon,
    strokeIconSrc: jazzStroke,
    width: 95,
    bgColor: "#E866BC",
    statsIcon: jazzStats,
  },
  {
    id: 4,
    name: "힙합",
    EngName: "HIPHOP",
    imgSrc: smallHiphopIcon,
    whiteImgSrc: whiteHiphopIcon,
    iconSrc: hiphopIcon,
    strokeIconSrc: hiphopStroke,
    width: 95,
    bgColor: "#5452FF",
    statsIcon: hiphopStats,
  },
  {
    id: 5,
    name: "Lo-Fi",
    EngName: "LOFI",
    imgSrc: smallLofiIcon,
    whiteImgSrc: whiteLoFiIcon,
    iconSrc: lofiIcon,
    strokeIconSrc: lofiStroke,
    width: 107,
    bgColor: "#FE60A2",
    statsIcon: lofiStats,
  },
  {
    id: 6,
    name: "댄스",
    EngName: "DANCE",
    imgSrc: smallDanceIcon,
    whiteImgSrc: whiteDanceIcon,
    iconSrc: danceIcon,
    strokeIconSrc: danceStroke,
    width: 95,
    bgColor: "#A64EEC",
    statsIcon: danceStats,
  },
  {
    id: 7,
    name: "기타",
    EngName: "EXTRA",
    imgSrc: smallEtcIcon,
    whiteImgSrc: whiteEtcIcon,
    iconSrc: etcIcon,
    strokeIconSrc: etcStroke,
    width: 95,
    bgColor: "#FFCD1D",
    statsIcon: etcStats,
  },
];
